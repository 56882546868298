body {
    min-width: 20rem;
}

.navbar {
    min-height: 4rem;
}

.nav-link:hover,
.text-link:hover {
    cursor: pointer;
}

.navbar-brand:hover {
    cursor: pointer;
}

/* INTRO */
.intro-bg-image {
    background-image: url('../../public/images/bg/wave1.png'), linear-gradient(to right, rgb(26, 57, 232), rgb(194, 25, 152));
    min-height      : 45rem;
    position        : relative;
    background-size : cover;
}

.intro-bg-bottom-wave {
    position   : absolute;
    /* bottom  : 14rem; */
    bottom     : -0.2rem;
}

.intro-content {
    padding-top: 7rem;
}

.intro-col {
    height: 12rem;
}

.avatar-wobble {
    height       : 16rem;
    border-radius: 80% 20% 81% 19% / 23% 81% 19% 77%;
    transform    : rotate(-2deg);
    animation    : wobble-article 7s ease-in-out alternate infinite;
}

/* SKILLS */
.skills-bg-image {
    background-image: url('../../public/images/bg/wave1.png'),
        linear-gradient(to right, rgb(39, 39, 39), rgb(60, 60, 60));
    min-height     : 25rem;
    position       : relative;
    background-size: cover;
}

.skill-hover-zoom {
    overflow: hidden;
}

/* .skill-hover-zoom img {
    transition: transform .2s ease;
}

.skill-hover-zoom:hover img {
    transform: scale(1.15);
} */


/* PROJECTS */
@property --myColor1 {
    syntax       : '<color>';
    initial-value: rgb(53, 53, 53);
    inherits     : false;
}

@property --myColor2 {
    syntax       : '<color>';
    initial-value: rgb(26, 26, 26);
    inherits     : false;
}

.img-hover-zoom {
    overflow: hidden;
}

.img-hover-zoom img {
    transition: transform .5s ease;
}

.project-card:hover {
    cursor: pointer;
}

.project-card .project-avatar {
    -webkit-filter    : grayscale(100%);
    filter            : grayscale(100%);
    -webkit-transition: 0.2s ease-in-out;
    transition        : 0.2s ease-in-out;
}

.project-avatar-spinner,
.project-avatar {
    min-height: 20rem;
}

.project-card-focus img {
    --myColor1    : rgb(73, 0, 80) !important;
    --myColor2    : rgb(10, 0, 100) !important;
    -webkit-filter: grayscale(0) !important;
    filter        : grayscale(0) !important;
}

.project-card-focus .img-hover-zoom img {
    transform: scale(1.1);

}

.project-bg-image {
    background-image: url('../../public/images/bg/wave3.png'),
        linear-gradient(to right, var(--myColor2), var(--myColor1));
    transition     : --myColor1 0.5s ease-in-out, --myColor2 0.5s;
    background-size: cover;
    color          : #0e0091;
}

/* MODAL */

@media (min-width: 768px) {
    .header-content {
        padding-top: 9rem;
    }

    .avatar-wobble {
        height: 17rem;
    }

    .header-bg-image {
        min-height: 45rem;
    }

    .modal-90w {
        width        : 90%;
        /* max-height: 90vh; */
        max-width    : 100vh !important;
    }

}

@media (min-width: 1625px) {
 
    .intro-bg-bottom-wave {
        position   : absolute;
        /* bottom  : 14rem; */
        bottom     : -8rem;
    }

}

@keyframes wobble-article {
    50% {
        border-radius: 750px 550px 350px 750px / 450px 750px 550px 650px;
    }

    100% {
        border-radius: 750px 250px 750px 250px / 450px 750px 250px 550px;
        transform    : rotate(2deg);
    }
}


/* OTHERS */
.text-justify {
    text-align: justify;
}

.unselectable {
    -moz-user-select   : none;
    -webkit-user-select: none;
    -ms-user-select    : none;
    user-select        : none;
}
