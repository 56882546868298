$theme-colors: (
    "primary": #0d6efd,
    "gray": #333333,
    "secondary": rgb(33, 37, 41),
    "light-gray": #d8d8d8,
    // "secondary": #2d3238,
    "light": #888888,
    "success": #39a883,
    "danger": #f73c33,
    "orange": #ffa349,
    "test": #ffffff,
    "yellow": #fffe00,
    "lime": #dfea87,
    "pink": #cb2ce0,
    "purple": #a013d8,
    "light-blue": #32beff,
    "dark-green": #137265,
);

// Link
$link-color: rgb(194, 41, 255);
$link-decoration: none;

// Input
$input-border-width: 0.15rem;
$input-bg: rgba(136, 136, 136, 0.042);
$input-focus-border-color: $link-color;
$input-focus-box-shadow: 2 2 $link-color;
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

// Button
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// // Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/variables";

// // Awesome Fonts
@import "~font-awesome/scss/font-awesome";
@import "~font-awesome/css/font-awesome.css";
